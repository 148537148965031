<template>

<com-content :blue="true" class="summary" :class="{'is-disabled': disabled}">

	<com-content :inner="true">

		<com-head :white="true" title="Your pricing example" text="See the cost per badge and other relevant information." icon="" />

		<div class="summary-wrapper">

			<div class="summary-badge">

				<div class="summary-badge-icon"><i class="fa-solid fa-id-card-clip"></i></div>

				<div class="summary-badge-content">

					<div class="summary-badge-content-level">

						<div class="summary-badge-content-level-fee">${{ fee }} per badge<small v-if="hasRegistration">+ $4.00 sales fee</small></div>

					</div>

				</div>

			</div>

			<div class="summary-features">

				<div class="summary-features-item">

					<div class="summary-features-item-text">

						<template v-if="hasRegistration">
							Any badge or merchandise purchases made though the platform are subject to a 4.0% fee. You can optionally pass the fees on to the customer.<br/>
							<br/>
							For example, if a badge is sold for $30.00 and you are not passing the fees on; the sale is subject to a ${{ fee }} badge fee and a $1.20 sales fee. The net revenue you receive is {{ (30 - fee - 1.2) | formatMoney }}.
						</template>

						<template v-if="!hasRegistration">
							As you are not using the Registration service, you will not be subject to any sales fees. Instead, you will import your attendee badges in the management panel and will be required to pay for the badges in order for them to be functional.<br/>
							<br/>
							For example, you import 500 badges; this will generate ${{ fee * 500 }} in badge fees. In order for those badges to be usable on the platform you will need to then pay the amount required.
						</template>

					</div>

				</div>

			</div>

		</div>

		<router-link :to="{ name: 'Signup' }" class="summary-button">Get started</router-link>

	</com-content>

</com-content>

</template>

<script>

export default {

	props: ['services', 'level', 'disabled', 'extras', 'data'],
	
	components: {

		'com-content': () => import('./../common/Content'),
		'com-head': () => import('./Head')

	},

	computed: {

		hasRegistration: function() {

			return this.$_.contains(this.services, 'registration')

		},

		fee: function() {

			var fee = 0

			this.$_.each(this.services, function(service) {

				service = this.$_.findWhere(this.data.services, {
					id: service
				})

				fee += (this.services.length === 3 && service.save !== false) ? service.save : service.fee

			}.bind(this))

			return fee.toFixed(2)

		}

	}

}

</script>

<style scoped>

.summary {
	padding: 50px 0px 40px 0px;
}

.is-mobile .summary {
	padding: 40px 10px 20px 10px;
}

.summary.is-disabled {
	pointer-events: none;
}

.summary.is-disabled > * {
	opacity: 0.25;
}

.summary-wrapper {
	display: flex;
}

.is-mobile .summary-wrapper {
	flex-direction: column;
	align-items: center;
}

.summary-text {
	flex-grow: 1;
	width: 320px;
}

.summary-features-item {
	width: 320px;
}

.is-mobile .summary-features-item {
	width: 100%;
}

.summary-features-item-head {
	display: flex;
	justify-content: space-between;
	color: #fff;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 1px;
	align-items: center;
}

.summary-features-item-head >>> .icon {
	color: #fabe7b;
	font-size: 24px;
}

.summary-features-item-text {
	margin-top: 10px;
	font-size: 14px;
	line-height: 18px;
	color: #fff;
	font-weight: 400;
	opacity: 0.5;
}

.summary-badge {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	align-items: center;
	width: 240px;
}

.summary-badge-icon {
	color: #fff;
	font-size: 128px;
}

.summary-badge-content {
	padding: 20px;
	color: #fff;
}

.is-mobile .summary-badge-content {
	padding: 20px 0px;
}

.summary-badge-content-level {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
}

.is-mobile .summary-badge-content-level {
	text-align: center;
	margin-bottom: 20px;
}

.summary-badge-content-level-text {
	font-size: 16px;
	font-weight: 300;
}

.summary-badge-content-level-fee {
	font-size: 20px;
	font-weight: 400;
	text-align: center;
}

.is-mobile .summary-badge-content-level-fee {
	font-size: 16px;
	margin-top: 4px;
}

.summary-badge-content-level-fee small {
	display: block;
	font-size: 12px;
	margin-top: 4px;
	font-weight: 300;
	opacity: 0.75;
}

.summary-button {
	font-size: 18px;
	color: #fff;
	font-weight: bold;
	padding: 15px 40px;
	margin-top: 40px;
	display: inline-block;
	text-transform: uppercase;
	background-color: #ffbe7a;
	letter-spacing: 1px;
	border-radius: 4px;
	font-weight: 600;
    color: #333;
	transition: transform 100ms linear;
}

.is-desktop .summary-button:hover {
	transform: scale(1.05);
}

.is-mobile .summary-button {
	font-size: 14px;
	padding: 15px 20px;
}

</style>